
.fontColor{
    color:palevioletred;
    text-align: center;
}
.backgroundMain{
   
  
    
    padding: 10%;
    padding-top: 10px;
    margin: auto;
    position: relative;
    height: 100%;
    background-image: url("./image/moochine_image/register/register_BG.png");
    background-repeat: no-repeat;
    background-size: cover;
    

}
.backgroundRegister_pets{
   
    padding: 10%;
    padding-top: 10px;
    margin: auto;
    position: relative;
    height: 1000px;
    background-image: url("./image/moochine_image/register\ pet/register-pet_BG.png");
    background-repeat: no-repeat;
    background-size: cover;
    
    

}
.home_cam{
    padding-left: 6.5%;
    
}
.register_cam{
    padding-left: 8%;
    
}
.editprofile_cam{
    padding-left: 7%;
    
}
.redeem_cam{
    padding-left: 7%;
    
}
.Header{
    font-size: 30px;
    color: #FF0099;
    text-align: center;
    
}
.Header_LuckyDraw{
    font-size: 45px;
    color: #FF0099;
    text-align: center;
    
}
.Header_register{
    font-size: 40px;
    color: #FF0099;
    text-align: center;
    
}
.Header_redeem{
    font-size: 24px;
    color: #ffffff;
    text-align: center;
    
}

.dog_icon{
    height: 50px;
    width: 10%;
    background-image: url("./image/moochine_image/home\ campaign/home campaign_icon1.png");
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
    
    display: inline-block;
}
.cat_icon{
    height: 50px;
    width: 10%;
    background-image: url("./image/moochine_image/home\ campaign/home campaign_icon2.png");
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
}

.clover_icon{
    height: 50px;
    width: 10%;
    background-image: url("./image/moochine_image/lucky_draw/clover_logo.png");
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
    
    display: inline-block;
}

.luckyDraw_text{
    height: auto;
    width: 100%;
    object-fit: cover;
    content: url("./image/moochine_image/lucky_draw/lucky_draw.png");
}

.luckyDraw_banner{
    height: auto;
    width: 100%;
    object-fit: cover;
    content: url("./image/moochine_image/lucky_draw/law_banner.png");
}


.dog_icon_redeem{
    height: 50px;
    width: 10%;
    background-image: url("./image/moochine_image/redeem/redeem_icon1.png");
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
    
    display: inline-block;
}
.cat_icon_redeem{
    height: 50px;
    width: 10%;
    background-image: url("./image/moochine_image/redeem/redeem_icon2.png");
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
}
.floatlelf{
    float:left;
}
body{
    margin: 0;
    padding: 0;
    
    
}
p{
    margin: 0;
    padding: 0;
}
.center{
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;

    
}
.resgisterFoodter{
    background-color: white;
    border-radius: 20px;
    
}
.padding20px{
    padding: 5%;
}

.displaychecklist{
    display: inline-block;
    width: 30px;
    top: 0;
    

}
.displaychecklistDetail{
    display: inline-block;
    word-wrap:break-word;
    padding-left: 2%;
    color : #828282;
    width: 250px;
    height: 500px;
    overflow: scroll;
    font-size: 10px;

   
   

}
.displaychecklistDetail_home{
    display: inline-block;
    word-wrap:break-word;
    padding-left: 2%;
    color : #828282;
    font-size: 14px;
    width: 100%;
    height: 500px;
    overflow: scroll;
    font-size: 10px;

   
   

}

/* ------ Home page ------- */
.backgroundHome{
   
  
  
    padding: 6%;
    padding-top: 10px;
    margin: auto;
    position: relative;
    height: 100%;
    background-image: url("./image/moochine_image/home\ campaign/home-campaign_BG.png");
    background-size: cover;
    background-repeat: no-repeat;
}
.backgroundPetShop{
    padding: 6%;
    padding-top: 10px;
    margin: auto;
    position: relative;
    height: 100%;
    background-image: url("./image/moochine_image/pet_shop/pet-shop_BG.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.image_1{
    width: 100%;
    height: 170px;
    background-color: rgb(213, 213, 213);
    
    
}

.ranking{
    width: 100%;
    height: 650px;
    background-image: url("./image/moochine_image/home\ campaign/pet\ shop_fix1/ranking_spender_fix1.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}
.rankingPetShop{
    width: 100%;
    height: 900px;
    /* background-image: url("./image/moochine_image/home\ campaign/pet\ shop_fix1/ranking_spender_fix1.png"); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}
.rank2{
    width: 33.3%;
    height: 280px;
    /* background-color: #396192;  */
    display:inline-block;
    
}
.circleRank2 {
    width: 68px;
    height: 68px;
    border-radius: 100%;
    /* margin-left: 23%; */
    margin-top: 118px;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
   
}
.fontrank2_3{
    font-size: 18px;
    color: #fff;
   
}
.fontrank2_3_name{
    font-size: 14px;
    color: #fff;
}

.rank1{
    width: 33.3%;
    height: 280px;
    background-color: #fbf600; 
    display:inline-block;
    
}
.fontrank1_name{
    font-size: 22px;
    color: #fff;
}
.circleRank1 {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    /* margin-left: 4%; */
    margin-top: 80px;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.rank3{
    width: 33.3%;
    height: 280px;
    background-color: #620404; 
    display:inline-block;
    
}
.profile_marginPoint{
    margin-top: 35px;
    padding-left: 50%;
}
.circleRank3 {
    width: 68px;
    height: 68px;
    border-radius: 100%;
    margin-top: 114px;
    /* margin-right: 17%; */
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.rowhome{
width: 350px;
padding-left: 1%;
}
@media screen and (min-width:280) and (max-width:340px) {
    .rowhome{
        padding-right: 15%;
        }
}

@media screen and (max-width: 375px) {
    .minmexSize3{
        padding-right:7%;
    }
    .minmexSize2{
        padding-left:7%;
    }
    .menu{
        position: fixed;
    }
   
}
@media screen and (min-width:360px) and (max-width: 360px) {
    .minmexSize3{
        padding-right:20%;
    }
    .minmexSize2{
        padding-right:8%;
    }
    .minmexSize1{
        padding-right:8px;
    }
    .profile_marginPoint{
        margin-top: 30px;
        padding-left: 50%;
    }
    .menu{
        position: fixed;
    }

   
}
@media screen and (min-width:390px) and (max-width: 393px) {
    .minmexSize3{
        padding-right:7%;
    }
    .minmexSize2{
        padding-left:7%;
    }
    .profile_marginPoint{
        margin-top: 35px;
        padding-left: 50%;
    }
    .menu{
        position: fixed;
    }
   
}

@media screen and (min-width:414px) and (max-width: 414px) {
    
    .profile_marginPoint{
        margin-top: 45px;
        padding-left: 50%;
    }
    .menu{
        position: fixed;
    }
   
}
@media screen and (min-width:412px) and (max-width: 412px) {
    
    .profile_marginPoint{
        margin-top: 48px;
        padding-left: 50%;
    }
    .menu{
        position: fixed;
    }
}

@media screen and (min-width:420px) and (max-width:485px) {

    
    body{
        width: 414px;
    }
    .menu{
        position: fixed;
    }
 
}
@media screen and (min-width:486px)  {

    
        body{
            width: 414px !important;
        }
       
    
  
    
}







.borderradius20px{
    border-radius: 20px;
}

.backgroundMainHome{
   
  
    background-color: #6E7B8B;
    padding: 10%;
    padding-top: 10px;
    margin: auto;
    position: relative;
    height: 100%;
   
}
.rank1-3{
    width: 100%;
    height: 300px;
    
}
.rank1-3PetShop{
    width: 100%;
    height: 450px;
    
}
.rankall{
    width: 100%;
    height: 290px;
    
    overflow: scroll;
}
.rankallPetShop{
    border-radius: 20px;
    width: 100%;
    height: 390px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("./image/moochine_image/pet_shop/list-pet-shop.png");
    
}

.circle {
    width: 32px;
    height: 32px;
    background-color: rgb(255, 235, 59);
    border: solid 2px  rgb(250, 250, 250);
    border-radius: 100%;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    /* display: inline-block; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    

}
.tr_home{
   
    border: solid 1px  rgb(250, 250, 250);
    border-radius: 25px;
    width: 95%;
    margin: auto;
    background: #fbceed; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, #fbceed, #ee96b3); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, #fbceed, #ee96b3); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, #fbceed, #ee96b3); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, #fbceed , #ee96b3); /* Standard syntax */
  
    

   
}

.center_home{
    align-items: center;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.font_rank_all{
    font-size: 13px;
    color: #CC3366;
    padding: 7px;
    text-align: center;
    margin-bottom: 5px;
    
    
}

.MyRank{
    font-size: 14px;
    color: #FFF;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    text-align: center;
    border: solid 1px  rgb(250, 250, 250);
    border-radius: 25px;
    margin-bottom: 5px;
    background-color: #EE3A8C;
    /* background: #f3c2c2; 
  background: -webkit-linear-gradient(left, #f3c2c2 , #FFD700); 
  background: -o-linear-gradient(right, #f3c2c2, #FFD700); 
  background: -moz-linear-gradient(right, #f3c2c2, #FFD700); 
  background: linear-gradient(to right, #f3c2c2 , #FFD700);  */

    
    
}
.backgroundMyRank{
    
    height:80px;
}
.textUpload{
    
    height:80px;
    color:white;
}
.font_ButtonUpload{
    font-size: 20px;
    text-align: center;
    width: 80%;
  
}
.font_ButtonRegi_pets{
    font-size: 20px;
    text-align: center;
    width: 50%;
  
}

.botton_size{
    width: 100%;
    display: inline-block;
    height: 45px;
  
        
}

.pink-checkbox .ant-checkbox-input {
    &:checked + .ant-checkbox-inner::after {
      background-color: pink; /* Change to your desired color */
    }
}

.botton_back{
    width: 25%;
    display: inline-block;
    height: 35px;
    margin-left: 6%;
    background-image: url("./image/moochine_image/sent\ address/sent address_back arrow.png");
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    
        
}
.icon_3{
    width: 10%;
    display: inline-block;
    height: 40px;
    background-image: url("./image/moochine_image/home\ campaign/home campaign_icon3.png");
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
        
}
.rules{
    padding: 20px;
 
}
.menu{
    height: 80px;
    background-color: #FF6699;
    border-radius: 40px 40px 0px 0px;
    text-align: center;
    color:white;
    
    bottom: 0;
    width: 105%;
    z-index:100;


}
.menu > .Col {
    flex: 1;
    text-align: center;
}
  
.menu > .Col:nth-child(3) {
    position: relative; /* Ensure relative positioning for the third column */
}

.menu_icon_home{
    background-image: url("./image/moochine_image/icon\ menu/icon_home.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 30px;
    
}
.menu_icon_profile{
    background-image: url("./image/moochine_image/icon\ menu/icon_profile.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 30px;
    
}
.menu_icon_redeem{
    background-image: url("./image/moochine_image/icon\ menu/icon_redeem.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 30px;
    
}
.menu_icon_upload{
    background-image: url("./image/moochine_image/icon\ menu/icon_upload.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 30px;
    
}
.menu_icon_lucky_draw{
    background-image: url("/assets/menu/lottery.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 30px;
    
}

/* --------Upload page --------- */
.backgroundUpload{
   
  
    
    padding: 10%;
    padding-top: 10px;
    margin: auto;
    position: relative;
    height: 100%;
    background-image: url("./image/moochine_image/upload/upload_BG.png");
   
    background-repeat: no-repeat;
    background-size: cover;
    

}

.input_money{
    width: 83%;
    display: inline-block;
}
.input_other{
    width: 100%;
}
.bath{
    width: 50px;
    display: inline-block;
}
.font_receipt{
    font-size: 25px;
    color: rgb(75, 70, 70);
    text-align: center;

}
.image_upload{
    width: 100%;
    height: 300px;
    
    /* background-color: #f2f2f2; */
    /* border-radius: 25px;
    border: solid 1px  rgb(138, 138, 138); */
    padding-top: 3%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    
}
.image_upload_receipt{
    width: 100%;
    height: 400px;
    /* background-color: #f2f2f2; */
    /* border-radius: 25px;
    border: solid 1px  rgb(138, 138, 138); */
    padding-top: 3%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    
}
.icon_upload_image{
    width: 140px;
    height: 140px;
    background-image: url("./image/moochine_image/upload/upload.png");
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 40px;

    /* margin-top: 20px; */
}

/* --------receipt_offline page --------- */
.dropdown_receipt_offline{
    width: 48.5%;
    display :inline-block;
}

/* --------redeem_rewards page --------- */
.backgroundRedeem_rewards{
   
  
    background-image: url("./image/moochine_image/redeem/redeem_BG.png");
    padding: 10%;
    padding-top: 10px;
    margin: auto;
    position: relative;
    background-size: cover;
    height: 1500px;
    overflow: auto;
  

    
}
.image_redeem{
    height: 200px;
    margin: 5%;
    width: 90%;
    background-color: #6E7B8B;
    border-radius: 20px;
    border: solid 1px  rgb(182, 172, 172);
}
.HeaderRedeem_rewards{
    font-size: 30px;
    color: #fcfcfc;
    text-align: center;
   
    
}
.MyPoint{
    color: #fcfcfc;
    margin: 3%;
    font-size: 18px;
}
.MyPoint_redeem{
    color: #fcfcfc;
    margin: 3%;
    font-size: 18px;
}
.NameRedeem{
    color:#ff3a89;
    text-align: center;

}
.bottonRedmeem{
    width: 100%;
    background-color: #ff3a89;
    border-radius: 25px;
    text-align: center;
    padding: 8px;
}
.bottonFont{
    
    color:white;
    text-align: center;
    font-size: 20px;
    
   
}
.pointfont{
    float:right;
    font-size: 20px;
}
.pointfont2{
    float:right;
    
}
.overflowRedeem{
   
    height: 550px;
    width: 90%;
    margin: auto;
}
.marginAutoRedeem{
    margin: auto;
    margin-bottom: 20px;
    border: solid 2px  rgb(250, 250, 250);

}
.marginAuto{
    margin: auto;
  
    
}
.formRange{
    width: 84%;
    display: inline-block;
    align-items: center;
}
.bowlRedeem{
    width: 18%;
    height: 100px;
    background-image: url("./image/moochine_image/profile/dogcat-point/trophy-gold.png");
    text-align: center;
    display: inline-block;
    align-items: center;
    background-size: 100%;
    background-repeat: no-repeat;
}
.bg_sumpoint{
    /* background-image: url("./image/moochine_image/profile/catdog_point/catdog_point/platinum/4-platinum.png"); */
    background-size: 100%;
    background-repeat: no-repeat;
    height: 100px;
}

.Header_regis_pets{
    font-size: 22px;
    color: #FF0099;
    text-align: center;
    
}

/* ----------------delivery page------------- */
.addressBox{
    font-size: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    background-color:whitesmoke;

}
.circleAddress {
    width: 55px;
    height: 55px;
    border: solid 2px  rgb(250, 250, 250);
    border-radius: 100%;
    margin-top: 10%;
    margin-left: 10%;
    

}
.AddressDelete{
    float: left;
    background-color: rgb(156, 38, 38);
    height: 100%;
    width: 100%;
    border-radius: 6%;
   
    
}
.Icon_AddressDelete{
    float: left;
    height: 30px;
    width: 20px;
    margin-left:35%;
    margin-top: 40%;
    background-image: url("./image/moochine_image/edit\ profile/edit_profile_icon1_bin.png");
    background-size: 85%;
    background-repeat: no-repeat;


    
}

.font_delivery{
    margin:10px ;
    
}
.backgroundInputColor{
    background-color: #828282;
}
/* ----------------edit profile page------------- */
.HeaderEditprofile{
    font-size: 26px;
    color: #FF0099;
    text-align: center;   
}
.AddressEditprofile{
    font-size: 34px;
    color: #FF0099;
    text-align: center; 
}
.backgroundEditProfile{
    padding: 10%;
    padding-top: 10px;
    margin: auto;
    position: relative;
    
    background-image: url("./image/moochine_image/edit\ profile/edit-profile_BG.png");
    background-size: cover;
    background-repeat: no-repeat;

}
.circlePets{
    width: 55px;
    height: 55px;

    border: solid 2px  rgb(250, 250, 250);
    border-radius: 100%;
    margin: 10px;
}
.font_pets{
    margin-left: 10%;
    font-size: 16px;
    margin-top: 10%;
    
}
/* ----------------profile page------------- */
.backgroundProfile{
   
  
    background-image: url("./image/moochine_image/profile/peofile_BG.png");
    background-size: cover;
    background-position: center;
    padding: 7%;
    padding-top: 10px;
    margin: auto;
    position: relative;
    height: 100%;
    

   
}
.box1_profile{
   
    height: 210px;
    /* background-color: #f0ed4d; */
}
.Sub1_box1_profile{
    height: 145px;
    /* background-color: #828282; */
    
}
.Myname_profile{
    height: 35px;
    background-color:#fff;
    text-align: center;
    
}
.Myname_Name_profile{
    height: 35px;
    color: #ed1e79;
   
    background-color: white;
    font-size: 20px;
}
.image_profile{
    height: 146px;
    width: 100%;
    float: right;
    
    background-size: cover;
    background-repeat: no-repeat;

}
.frame_profile{
    width: 100px;
    height: 100px;
    /* background-image: url("./image/user/user.jpeg"); */
    border: solid 2px  rgb(250, 250, 250);
    border-radius: 100%;
    margin-top: 20px;
    background-size: cover;
    background-position: center;
    padding-bottom: 10%;    
}
.ranking_profile{
    height: 35px;
    /* background-image: url("./image/box_image/member/platinum-member.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    

}
.detial_profile{
    /* background-color: aquamarine; */
    float: left;
    width: 100%;
}
/* .icon_editProfile{
    height: 35px;
    background-color: #CC3399;
  
   
} */
.detial_profile2{

    /* background-color: rgb(161, 127, 255); */
    float: left;
    width: 100%;
    text-align: right;
    padding-top: 1%;
    padding-right: 6%;
    margin-top: 4%;
}
.cumulative1{
    font-size: 12px;
}
.cumulativeMoney{
    font-size: 16px;
}
.profile_pets{
    color: #FFF;
    font-size: 22px;
    padding-left: 6%;
    text-shadow: black 0.1em 0.1em 0.2em
}
.box_profile_pets{
    width: 90%;
    height: 360px;
    margin: auto;
}
.box_ฺBanner{
    
    margin: auto;
    height: 220px;
    margin-top: 10px;

}
.box_BannerPetShop{
    
    margin: auto;
    height: 300px;
    margin-top: 10px;
    /* background-image: url("./image/moochine_image/profile/pet\ profile/1x/profile_pet\ card.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}
.IN_box_profile_pets{
    width: 98%;
    height: 320px;
    margin: auto;
    background-image: url("./image/moochine_image/profile/pet\ profile/1x/profile_pet\ card.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}
.Sub1_box_profile_pets{
    font-size: 26px;
    color:white;
    margin-bottom: 5%;

    
}
.Sub2_box_profile_pets{
   
 padding-top: 5%;
 margin-left: 4%;
    
}
.Sub2_profile_pets{
   
  height: 100px;
       
   }
.frame_pets{
    width: 200px;
    height: 170px;
    border: solid 2px  rgb(250, 250, 250);
    border-radius: 150px  100px 120px 100px;
    background-image: url("https://res.cloudinary.com/dysfeos3s/image/upload/v1679422169/no-image-icon-23492_vlwssb.png");
    background-size: 50%;
    background-position: center;
    object-fit: cover;
    object-position: center;
    background-repeat: no-repeat;
   
}

.HeadteadePoint{
    padding: 1.5%;
    padding-bottom: 3%;
    margin-bottom: 5px;
    color:#fff;
    font-size: 16px;
    background-image: url("./image/moochine_image/profile/peofile_icon2.png");
    background-size: 95%;
    background-position: center;
    height: 40px;
    padding: 8px;
    background-repeat: no-repeat;
}

.Luckydraw-Sub-Header{
    padding: 1.5%;
    padding-bottom: 3%;
    margin-bottom: 5px;
    color:#fff;
    font-size: 16px;
    background-image: url("./image/moochine_image/lucky_draw/sub_header.png");
    background-size: 95%;
    background-position: center;
    height: 40px;
    padding: 8px;
    background-repeat: no-repeat;
}

.teadePointDetail{
    overflow: scroll;
    height: 80px;
    color:white;
    
}
.bottonProfile{
    
    color:#FF0099;
    text-align: center;
    font-size: 14px;
   
}
.boxRecord{
    
    width: 35%;
    font-size: 24px;
    color:#FF0099;
    border-radius: 10px;
    margin-bottom: 10px;
    background-image: url("./image/moochine_image/profile/profile_icon1.png");
    background-size: cover;
    background-position: center;
    text-align: right;
    padding-right: 10px;

}
.back_page{
    font-size: 28px;
}.success{
    width: 80px;
    height: 100px;
    background-size: 85%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./image/moochine_image/success-icon.png");

}

/* ----------------lucky_draw_detail page------------- */

.lucky_current_point {
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    background-color: #fff;
    max-width: 'auto';
    white-space: nowrap;
    text-align: center;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.lucky_current_draw {
    justify-content: center;
    align-self: stretch;
    border-radius: 30px;
    border-color: rgba(254, 210, 49, 1);
    border-style: solid;
    border-width: 2px;
    background-color: #ff3a89;
    border-color: #fff;
    max-width: 'auto';
    margin-top: 30px;
    flex-direction: column;
    padding: 16px 10px;
}
.lucky_current_draw_detail {
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    background-color: var(--Yellow, #fed231);
    display: flex;
    margin-top: 16px;
    width: 100%;
    color: var(--Text, #212121);
    font-weight: 600;
    white-space: nowrap;
    text-align: center;
    padding: 8px;
}

.backgroundLuckyDrawDetail{
    padding: 6%;
    padding-top: 10px;
    margin: auto;
    position: relative;
    height: 100%;
    background-image: url("./image/moochine_image/lucky_draw/LuckyDrawDetail_BG.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.exchange_lucky_draw {
    width: 100%;
    display: inline-block;
    height: 45px;
    border-radius:14px;
    background-color:#fed231;
    border-color: rgba(254, 210, 49, 1);
    color: #212121;
}

.lucky_draw2Upload {
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    display: inline-block;
    height: 45px;
    border-radius:14px;
    background-color:#fff;
    border-color: #fff;
    color: #ff3a89;
}

.lucky_current_draw_exchange{
    justify-content: center;
    border-radius: 60px;
    background-color: var(--Yellow, #fed231);
    display: flex;
    width: 50%;
    /* max-width: 100px; */
    /* margin-left: 60px ;
    margin-right: 60px; */
    color: var(--Text, #212121);
    font-weight: 600;
    text-align: center;
    padding: 8px;
}

.confirm_exchange_lucky_draw {
    width: 100%;
    display: inline-block;
    height: 45px;
    border-radius:14px;
    background-color:var(--Yellow, #fed231);
    border-color: var(--Yellow, #fed231);
    color: #212121;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
